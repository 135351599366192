
    import {computed, defineComponent, onMounted, reactive, toRefs,ref} from 'vue';
    import ApiUser from "@/request/apis/api_user";
    import PageUtil from "@/utils/page_util";
    import {message} from "ant-design-vue/es";
    import ApiSetting from "@/request/apis/api_setting";

    interface IState {
        searches:any,
        total:any,
        list:any,
        treeData:any,
        platform_cate:any,
        title:any,
        goodsReAuditId:any,
        goodsReAuditName:any,
        visible:any,
        reason:any,
        type:any,
        shareVisable:any,
        goods_id:any,
        ratio:any,
        sort:any,
        settingData:any,
        settingVisable:any,
        colunData:any
    }


    export default defineComponent({
        name: 'salaryRange',
        components: {},
        setup() {
            let state: IState = reactive({
                settingVisable:false,
                colunData:[],
                searches:{
                    shop_name:'',
                    goods_name:'',
                    type:1,
                    platform_cate_id:'',
                    page_no:PageUtil.pageNo,
                    page_size:PageUtil.pageSize
                },
                settingData:{
                    goods_id:'',
                    select:'',
                    sales_virtual:'',
                    sort_weight:''
                },

                total:0,
                ratio:'',
                sort:'',
                goods_id:'',
                platform_cate:[],
                list:[],
                treeData:[],
                title:"",
                goodsReAuditId:'',
                goodsReAuditName:'',
                visible:false,
                type:"",
                reason:'',
                shareVisable:false
            })
            const columns = [
                {
                    title: '商家信息',
                    dataIndex: 'shop',
                    key: 'shop',
                    width:500
                },
                {
                    title: '商品信息',
                    dataIndex: 'goods',
                    key: 'goods',
                    width:550
                },
                {
                    title: '销售数据',
                    dataIndex: 'sale',
                    key: 'sale',
                    width: 300
                },
                {
                    title: '修改时间',
                    key: 'update_time',
                    dataIndex: 'update_time',
                },
                {
                    title: '审核说明',
                    key: 'tags',
                    dataIndex: 'tags',
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 270
                },
            ];

            const getList = async() => {

                if(state.platform_cate.length >0){
                    state.searches.platform_cate_id=state.platform_cate.join(',')
                }
                const params = {...state.searches}
                const res = await ApiUser.GoodList(params)
                if(res.error_code == 0){
                    const data:any = res.data
                    state.total = data.count
                    state.list  = data.lists
                }
            }

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page_no,
                pageSize: state.searches.page_size,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));
            const handleTableChange = (pages: any) => {
                state.searches.page_size = pages.pageSize
                state.searches.page_no = pages.current
                getList()
            };


            const OnSearch = () => {
                state.searches.page_size = PageUtil.pageSize
                state.searches.page_no = 1
                getList()
            }

            const OnReset = () => {
                state.searches.shop_name=''
                state.searches.goods_name=''
                state.searches.platform_cate_id = ''
                state.platform_cate = []
                state.searches.page_no = PageUtil.pageNo
                state.searches.page_size = PageUtil.pageSize
                getList()
            }

            const getTree = async() => {
                const params = {}
                const res = await ApiUser.categoryList(params)
                if(res.error_code == 0){
                    const data:any = res.data
                    state.treeData = data
                    state.treeData= JSON.parse(JSON.stringify(state.treeData).replace(/"name"/g,"\"label\"").replace(/"id"/g,"\"value\"").replace(/"sub"/g,"\"children\""))
                }
            }
            const callback = (key) => {
                state.searches.page_no = PageUtil.pageNo
                state.searches.page_size = PageUtil.pageSize
                state.searches.type = key
                getList()
            }

            const goodsReAudit = (val,val2) => {
                state.reason = ''
                state.goodsReAuditId=val.id,
                state.goodsReAuditName =val.name
                state.title = '商品审核：' + state.goodsReAuditName
                state.type = val2
                state.visible = true
            }

            const handleOk = async(val) => {
                if(state.type == 1){
                    const params = {
                        "goods_id":state.goodsReAuditId,
                        "audit_remark":state.reason,
                        "audit_status":val,
                    }
                    const res = await ApiSetting.goodsAudit(params)
                    if(res.error_code == 0){
                        state.visible = false
                        getList()
                        state.visible = false
                        message.success(res.msg)
                    }else{
                        message.error(res.msg)
                    }
                }
                if(state.type == 2){
                    const params = {
                        "review_status":val,
                        "audit_status":val,
                        "review_desc":state.reason,
                        "goods_id":state.goodsReAuditId,
                        "audit_remark":state.reason,
                        "reason":state.reason,
                    }
                    const res = await ApiSetting.goodsReAudit(params)
                    if(res.error_code == 0){
                        state.visible = false
                        getList()
                        state.visible = false
                        message.success(res.msg)
                    }else{
                        message.error(res.msg)
                    }
                }
            }

            const share = (val) => {
                state.sort = ''
                state.ratio = ''
                state.shareVisable = true
                state.goods_id = val.id
            }

            const shareSub = async() => {
                const params = {
                    "goods_id":state.goods_id,
                    "ratio":state.ratio,
                    "sort":state.sort,
                }
                const res = await ApiSetting.distributionAdd(params)
                    if(res.error_code == 0){
                        getList()
                        state.shareVisable = false
                        message.success(res.msg)
                    }else{
                        message.error(res.msg)
                    }
            }

            
            const setting_sub = async() => {
                const params = {...state.settingData}
                params.select = params.select.join(',')
                const res = await ApiSetting.setInfo(params)
                    if(res.error_code == 0){
                        getList()
                        state.settingVisable = false
                        message.success(res.msg)
                    }else{
                        message.error(res.msg)
                    }
            }

            const setting = (record) => {
                console.log(record)
                state.settingData.goods_id = record.id
                state.settingData.select = []
                state.settingData.sales_virtua = record.id
                state.settingData.sort_weight = record.id
                state.settingVisable = true
                getLanmu()
            }

            const getLanmu = async() => {
                const params = {page:1,limit:999}
                const res = await ApiUser.ColumnLists(params)
                    if(res.error_code == 0){
                        const data :any= res.data
                       state.colunData = data.lists
                       console.log(state.colunData)
                    }else{
                        message.error(res.msg)
                    }
            }
            onMounted(() => {
                getList()
                getTree()
            })

            return {
                ...toRefs(state),
                getList,
                callback,
                setting,
                OnReset,
                handleOk,
                shareSub,
                goodsReAudit,
                getTree,
                getLanmu,
                columns,
                share,
                OnSearch,
                pagination,
                setting_sub,
                handleTableChange
            }
        }
    });
